import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogSharingService {

  readonly BLOGGER_AUTHOR_ID_PAUL = '12916791224785933397';
  readonly BLOGGER_SUPERSTAR_LABEL = 'SuperStar';

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  processPosts(posts: any[], mode: string): any[] {

    // If this is BLOG mode remove any SuperStar posts
    if (mode === 'BLOG') {
      posts = posts.filter(post => !post.labels || !post.labels.includes(this.BLOGGER_SUPERSTAR_LABEL));
    }

    posts.forEach(post => {

      // Get the first image in the post. This will be our thumbnail
      post.thumbnail = this.getFirstImage(post.content);

      // Set the final post content
      post.content = this.html2PlainText(post.content);

      // Get the path from the full URL e.g. 2021/02/my-blog-post
      post.path = post.url.split('/').slice(3).join('/');

      // Is this post created by Paul?
      post.author.isPaul = post.author.id === this.BLOGGER_AUTHOR_ID_PAUL;

      // Set post weight (e.g. label "Weight10" is 10, "Weight45" is 45)
      post.weight = 0;
      if (post.labels) {
        post.labels.forEach(label => {
          const weightComponents = label.split('Weight');
          if (weightComponents.length === 2) {
            post.weight = parseInt(weightComponents[1], 10);
          }
        });
      }
    });

    // Order by [!isPaul, weight, published]
    return posts.sort((a, b) =>
      (a.author.isPaul - b.author.isPaul) || (b.weight - a.weight) || (b.published - a.published)
    );
  }

  private getFirstImage(html) {

    // If we are not in browser (SSR?), no image
    if (!isPlatformBrowser(this.platformId)) {
      return '';
    }

    const parser = new DOMParser();
    html = parser.parseFromString(html, 'text/html');
    const img = html.querySelector('img');
    return img ? img.src : '';
  }

  private html2PlainText(html) {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, '\n');
    html = html.replace(/<[^>]+>/ig, '');
    return html.replace(/&nbsp;/g, ' ').trim();
  }
}
